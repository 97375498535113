import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource';
import vClickOutside from 'v-click-outside'

Vue.use(VueResource);
Vue.use(vClickOutside)

document.addEventListener('DOMContentLoaded', () => {
  let rootElement = document.querySelector('#notifications');
  if (!rootElement) {return}
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  new Vue({
    el: rootElement,
    data: {
      companies: [],
      notifications: [],
      showNotifications: false,
      unreadCount: 0,
      expandedId: null
    },
    created: function () {
      let vm = this;
    },
    mounted() {
      this.loadNotifications();
      let timerId = window.setInterval(() => {
        // this.loadNotifications()
      }, 15000)
      // stop getting new notifications after 5 minutes
      setTimeout(() => { clearInterval(timerId); }, 300000);
    },
    methods: {
      closeNotificationWindow() {
        setTimeout(() => this.showNotifications = false, 450)
      },
      triggerNotificationWindow() {
        if (this.companies.length > 0) {
          this.expandedId = this.companies[0].id
        }
        if (!this.showNotifications && this.unreadCount > 0) {
          let notification_ids = []
          if (this.notifications.length > 0) {
            notification_ids = this.notifications.map(n => n.id)
          } else if (this.expandedId !== null){
            notification_ids = this.companies.find ( c => c.id === this.expandedId).notifications.map(n => n.id)
          }
          this.markRead(notification_ids)
        }
        this.showNotifications = !this.showNotifications
      },
      loadNotifications() {
        this.$http.get(`/notifications`).then(response => {
          this.unreadCount = response.body.unread_count;
          this.notifications = response.body.notifications;
          this.companies = response.body.companies;
        }, response => {
          // toastr.error(response.body.error, I18n.t('js.shared.flash.error'));
        })
      },
      itemClass(status) {
        return status ? '' : 'unread'
      },
      companyClass(id) {
        return this.expand_get(id) ? '' : 'closed'
      },
      markRead(ids) {
        this.$http.put(`/notifications/mark_read`, {notification_ids: ids}).then(response => {
        }, response => {})
      },
      expand_get(id) {
        return this.expandedId === id
      },
      expand_set(id) {
        if (this.expandedId === id) {
          this.expandedId = null;
        } else {
          this.expandedId = id;
          let notification_ids = this.companies.find ( c => c.id === this.expandedId).notifications.map(n => n.id)
          this.markRead(notification_ids)
        }
      },
    }
  })
});